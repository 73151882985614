import { template as template_1e7c5e5d392e4d778b80a6aaec72b204 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_1e7c5e5d392e4d778b80a6aaec72b204(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
